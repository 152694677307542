import { AuthApi } from '@/libraries/api'

function signOut() {
  const url = '/account/sign_out'
  return AuthApi().post(url)
}

export default {
  signOut
}
