<template>
  <v-dialog
    v-model="dialog"
    persistent
    min-width="310"
    max-width="600"
    overlay-opacity="0.97"
  >
    <v-card>
      <v-card-title class="headline">
        인증 세션이 만료되었습니다.
      </v-card-title>
      <v-card-text>
        사용자의 인증 세션이 만료되었습니다.
        <br>
        로그아웃을 하시거나 인증 연장을 버튼을 통해 로그인을 연장할 수 있습니다.
      </v-card-text>
      <v-card-actions class="mb-5">
        <v-spacer />
        <v-btn
          color="primary"
          class="white--text mx-4"
          @click="refreshToken"
        >
          <v-icon
            small
            class="mr-2"
          >
            refresh
          </v-icon>
          로그인 연장
        </v-btn>
        <v-btn
          class="mx-4"
          @click="signOut"
        >
          <v-icon
            small
            class="mr-2"
          >
            logout
          </v-icon>
          로그아웃
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthModel from '../models/auth'

export default {
  data() {
    return {
      dialog: false
    }
  },
  created() {
    const self = this
    this.$bus.$on('showModelAuthenticationExpired', () => {
      self.dialog = true
    })
  },
  methods: {
    signOut() {
      this.$bus.$emit('signOut')
    },
    async refreshToken() {
      const res = await AuthModel.renewAuthenticationByRefreshToken(this)
      if (res === true) {
        this.dialog = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
