import { AuthApi } from '@/libraries/api'

function checkToken() {
  const url = '/account/token'
  return AuthApi().get(url)
}

function updateToken(userEmail, userPwd) {
  const url = '/account/me/fetch_token'
  const dt = {
    device_type: 'web',
    user_email: userEmail,
    user_pwd: userPwd
  }
  return AuthApi().post(url, dt)
}

function renewToken() {
  const url = '/account/token/renew'
  return AuthApi().post(url)
}

export default {
  checkToken,
  updateToken,
  renewToken
}
