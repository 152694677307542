<template>
  <v-list>
    <v-list-item>
      <v-list-item-avatar class="layout-profile-avatar-wrap">
        <v-img
          :src="profileImage"
        />
      </v-list-item-avatar>
      <v-list-item-content
        style="flex: auto;"
        class="py-0"
      >
        <v-list-item-title class="text-body-2">
          {{ userExtra.nickname }}
          <span class="text-caption">님</span>
        </v-list-item-title>
        <v-list-item-subtitle class="text-caption">
          {{ user.mail }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content
        class="pl-2 ml-2 py-0"
        style="min-width: 52px; width: 52px;"
      >
        <SignoutButton />
        <AuthenticationExpiredModal />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'
import SignoutButton from '@/modules/account/components/SignoutButton'
import AuthenticationExpiredModal from '@/modules/account/components/AuthenticationExpiredModal'
import AccountAuthModel from '@/modules/account/models/auth'

export default {
  components: {
    SignoutButton,
    AuthenticationExpiredModal
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('Account', ['user']),
    ...mapState('Account', ['userExtra']),
    profileImage() {
      return (this.userExtra.photoUrl) ? this.userExtra.photoUrl : '/images/profile_pics/default.png'
    }
  },
  created() {
    AccountAuthModel.checkToken(this)
  },
  destroyed() {
    AccountAuthModel.stopCheckToken(this)
  }
}
</script>

<style lang="scss">
.v-navigation-drawer--mini-variant .layout-profile-avatar-wrap {
  width: 24px !important;
  min-width: 24px !important;
  height: 24px !important;
}
</style>
