import { initializeAccesstoken } from '@/libraries/api'
import SignOutApi from '../api/signOut'
import asyncInterval from './asyncInterval'

async function signOut(app) {
  let r = false
  try {
    const api = await SignOutApi.signOut()
    if (api.data.result === true) {
      // eslint-disable-next-line no-unused-vars
      const res = api.data.data
      initializeAccesstoken()
      await app.$store.dispatch('App/initialize')
      await app.$store.dispatch('Account/initialize')
      asyncInterval.clearAsyncInterval(0)
      r = true
    }
  } catch (e) {
    // console.log(e)
    r = false
  }
  return r
}

export default {
  signOut
}
