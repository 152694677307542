import { setUserAccessToken } from '@/libraries/api'
import AuthApi from '../api/auth'
import AsyncInterval from './asyncInterval'
// import SignOutModel from './signOut'

// eslint-disable-next-line camelcase
// type tokenItem = {access_token: string; refresh_token: string; token_type: string, expire_in: Number }

async function setAccessToken(app, item) {
  setUserAccessToken(item.access_token)
  app.$store.dispatch('Account/setToken', item.access_token)
  app.$store.dispatch('Account/setRefreshToken', item.refresh_token)
  app.$store.dispatch('Account/setExpiredAt', item.expire_in_minutes)
}

async function checkToken(app) {
  AsyncInterval.setAsyncInterval(async () => {
    let isExpired = false
    try {
      const req = await AuthApi.checkToken()
      isExpired = req.data.is_expired
    } catch (e) {
      if (e.response.status === 401) {
        isExpired = true
      }
    }
    if (isExpired === true) {
      app.$bus.$emit('showModelAuthenticationExpired')
    }
  }, 300000)
}

// eslint-disable-next-line no-unused-vars
async function stopCheckToken(app) {
  AsyncInterval.clearAsyncInterval(0)
}

async function renewAuthenticationByToken(app) {
  let r = false
  const res = await AuthApi.renewToken()
  if (res.data) {
    r = true
    await setAccessToken(app, res.data)
  }
  return r
}

async function renewAuthenticationByRefreshToken(app) {
  let r = false
  const refreshToken = app.$store.getters['Account/getRefreshToken']
  setUserAccessToken(refreshToken)
  const res = await AuthApi.renewToken()
  if (res.data) {
    r = true
    await setAccessToken(app, res.data)
  }
  return r
}

export default {
  checkToken,
  setAccessToken,
  stopCheckToken,
  renewAuthenticationByToken,
  renewAuthenticationByRefreshToken
}
